<template>
  <div>
    <!-- Filters -->
    <users-list-filters
      :role-filter.sync="roleFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :status-options="statusOptions"
      :range-date-filter.sync="rangeDateFilter"
      :role-filter1.sync="roleFilter1"
      :role-options1="roleOptions1"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="4">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>

          <b-col cols="12" md="4">
            <div class="d-flex align-items-center justify-content-end">
              <b-button variant="gradient-primary" @click="exportExcel">
                <feather-icon icon="DownloadIcon" />
                Export to Excel
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: User -->
        <template #cell(img)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.img"
                :to="{
                  name: 'apps-employee-view',
                  params: { id: data.item.id },
                }"
              />
            </template>
          </b-media>
        </template>
        <template #cell(rank)="data">
          <b-media vertical-align="center">
            <template #aside>
              <feather-icon
                icon="AwardIcon"
                size="20"
                class="mr-0 mr-sm-50 text-success"
              />
              <h4 class="text-primary">#{{ data.item.rank }}</h4>
            </template>
          </b-media>
        </template>
        <template #cell(saleId)="data">
          <b-media vertical-align="center">
            <template #aside> </template>
            <b-link class="font-weight-bold d-block text-nowrap">
              # {{ data.item.saleId }}
            </b-link>
          </b-media>
        </template>

        <template #cell(total_pay_product)="data">
          <b-media vertical-align="center">
            <template #aside>
              <p>${{ data.item.total_pay_product }}</p>
            </template>
          </b-media>
        </template>

        <template #cell(total_pay_service)="data">
          <b-media vertical-align="center">
            <template #aside>
              <p>${{ data.item.total_pay_service }}</p>
            </template>
          </b-media>
        </template>

        <template #cell(total_pay_tips)="data">
          <b-media vertical-align="center">
            <template #aside>
              <p>${{ data.item.total_pay_tips }}</p>
            </template>
          </b-media>
        </template>

        <template #cell(totalc)="data">
          <b-media vertical-align="center">
            <template #aside>
              <p>${{ data.item.totalc }}</p>
            </template>
          </b-media>
        </template>

        <template #cell(totalcandt)="data">
          <b-media vertical-align="center">
            <template #aside>
              <p>${{ data.item.totalcandt }}</p>
            </template>
          </b-media>
        </template>

        <!-- Column: Date -->
        <template #cell(sales_created)="data">
          <b-media vertical-align="center">
            <template #aside> </template>
            {{ formatDate(data.item.sales_created) }}
          </b-media>
        </template>

        <!-- Column: Customer -->
        <template #cell(customerId)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize"
              >{{ data.item.customers_first_name }}
              {{ data.item.customers_last_name }}</span
            >
          </div>
        </template>

        <!-- Column: Employee -->
        <template #cell(employeeId)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize"
              >{{ data.item.employees_first_name }}
              {{ data.item.employees_last_name }}</span
            >
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status === true ? "Active" : "Inactive" }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{
                // name: 'apps-invoice-preview',
                params: { id: data.item.saleId },
              }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Detail</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import router from "@/router";
import store from "@/store";
import * as XLSX from "xlsx";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import UsersListFilters from "./UsersListFilters.vue";
import useUsersList from "./useUsersList";
import userStoreModule from "../../userStoreModule";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    UsersListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const isAddNewUserSidebarActive = ref(false);

    const toast = useToast();

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      dataItemStatus,
      dataItemOffice,
      rangeDateFilter,
      roleFilter1,
      dataOfficeFilter,
    } = useUsersList();

    const roleOptions = dataItemOffice;
    const roleOptions1 = dataOfficeFilter;

    const statusOptions = dataItemStatus;

    const userData = ref(null);

    const totales = ref();
    const parser = JSON.parse(localStorage.getItem("userData"));
    const idOffice = parser.dataUser.officeEmployee_officeId;
    const idRol = parser.userData.rolId;
    const exportExcel = function () {
      const parser = JSON.parse(localStorage.getItem("userData"));
      const dataToExport = [];
      const query = {
        q: searchQuery.value.toLowerCase(),
        perPage: "",
        page: "",
        sortBy: "totalcandt",
        sortDesc: true,
        rangeDate: rangeDateFilter.value,
        employeeId:
          parser.roleId === 4
            ? parser.dataUser.Employeess_id
            : roleFilter.value,
        idOffice: parser.dataUser.officeEmployee_officeId,
        idRol: parser.dataUser.rolId,
      };
      store
        .dispatch("app-user/fetchPaymamentEmployeesExcel", query)
        .then((response) => {
          userData.value = response.data.paymentEmployees;
          response.data.paymentEmployees.forEach((element) => {
            dataToExport.push({
              RANK: element.rank,
              NAME: element.name,
              "COMISSION PRODUCT": element.total_pay_product,
              "COMISSION SERVICE": element.total_pay_service,
              TIPS: element.total_pay_tips,
              "COMISSION TOTAL": element.totalc,
              "COMISSION & TIPSTOTAL": element.totalcandt,
            });
          });

          totales.value = {
            totalPayProduct: response.data.totalPayProduct,
          };

          var wscols = [
            { wch: 10 },
            { wch: 30 },
            { wch: 20 },
            { wch: 20 },
            { wch: 10 },
            { wch: 20 },
            { wch: 20 },
          ];

          let data = XLSX.utils.json_to_sheet(dataToExport);
          data["!cols"] = wscols;
          const workbook = XLSX.utils.book_new();
          const filename = "Payments_employee";
          XLSX.utils.book_append_sheet(workbook, data, filename);
          XLSX.writeFile(workbook, `${filename}.xlsx`);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            userData.value = undefined;
          }
        });
    };

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      totales,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      rangeDateFilter,
      roleFilter1,
      roleOptions1,
      formatDate: (date) => {
        return date.slice(0, 10);
      },

      exportExcel,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.card-body {
  position: relative;
  .pie-text {
    width: 105px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 44%;
    bottom: 0;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
